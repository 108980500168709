import React from "react"

import Layout from "../layouts/layout"
import SEO from "../components/seo"

const StandardPage = (props) => (
  <Layout location={props.location}>
    <SEO title="Terms of Use" keywords={[`gatsby`, `application`, `react`]} />
    <div className="container py-5">
      <div className="row">
        <div className="col-12 col-md-10 offset-md-1">

        <h2>Simple Summers LLC</h2>
        <h2>Website and Services Terms of Use</h2>

        <p className="font-italic">Date Terms of Service Last Modified: March 18th, 2019</p>

        <p>These terms of use are entered into by and between you and Simple Summers LLC (“Company”, “we” or “us”). The following terms and conditions, together with any documents they expressly incorporate by reference (collectively, these “Terms of Use”), govern your access to and use SimpleSummers.com (the “Website”), including any content, functionality and services offered on or through SimpleSummers.com (collectively, the “Services”), whether as a guest or a registered user.</p>

        <ol>

            <li>
                <h4>ACCEPTANCE OF THE TERMS OF USE.</h4>
                <p>Please read the Terms of Use carefully before you start to use the Services. By accessing the Website and/or accepting to the Terms of Use when this option is made available to you, you accept and agree to be bound and abide by these Terms of Use and our Privacy Policy which is incorporated into these Terms of Use by this reference. If you do not want to agree to these Terms of Use or the Privacy Policy, you must not access or use our Services.</p>
                <p>The Services are offered and available to users who are 18 years of age or older. By using the Services, you represent and warrant that you are of legal age to form a binding contract with the Company. If you are under age 18, you must not access or use the Services.</p>
            </li>

            <li>
                <h4>CHANGES TO THE TERMS OF USE.</h4>
                <p>We may revise and update these Terms of Use from time to time in our sole discretion. All changes are effective immediately when we post them on our Website, and apply to all access to and use of the Services thereafter. Your continued use of the Services following the posting of revised Terms of Use means that you accept and agree to the changes. You are expected to check this page from time to time so you are aware of any changes, as they are binding on you.</p>
            </li>

            <li>
                <h4>ACCESSING THE WEBSITE AND ACCOUNT SECURITY.</h4>
                <p>We reserve the right to withdraw or amend the Services in our sole discretion without notice. We will not be liable if for any reason all or any part of the Services are unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Services, including, without limitation, the entire Website, to users, including registered users.</p>

                <p>If you are a camp or event organizer or otherwise responsible for providing information to the Services (including mobile applications) (collectively, “Organizations”) you agree that you are responsible for:</p>

                <p>Making all arrangements necessary for your authorized users to have access to provide information to the Services. Ensuring that all persons who access the Website through your internet connection or device or under your account are aware of these Terms of Use and comply with them.</p>

                <p>To access the Services, users may be asked to provide certain registration details or other information. It is a condition of your use of the Services that all the information you provide to the Company is correct, current and complete. You agree that all information you provide to register for the Services, including but not limited to through the use of any interactive features or subscriptions services on the Website, is governed by our Privacy Policy, and you agree that all actions we take with respect to your information consistent with our Privacy Policy are taken by us to comply with our contractual obligations to you in providing the Services.</p>

                <p>If you choose, or are provided with, a user name, password or any other piece of information as part of our security procedures, you must treat such information as confidential, and you must not disclose it to any other person or entity. You also acknowledge that your account is personal to you and agree not to provide any other person with access to this Website or portions of it using your user name, password or other security information. You agree to notify us immediately of any unauthorized access to or use of your user name or password or any other breach of security. You also agree to ensure that you exit from your account at the end of each session. You should use particular caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information.</p>

                <p>We have the right to disable any user name, password or other identifier, whether chosen by you or provided by us, at any time if, in our opinion, you have violated any provision of these Terms of Use.</p>
            </li>

            <li>
                <p>INTELLECTUAL PROPERTY RIGHTS. The Services and entire contents, features and functionality (including but not limited to all information, software, text, displays, images, video and audio, and the design, selection and arrangement thereof), are owned by the Company, its licensors or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws (“Intellectual Property Rights”). You acknowledge and agree that the Company (and/or its licensors) exclusively own all right, title and interest in and to the Services, copyright rights, trade secret rights, trademarks, database rights and any other intellectual property rights anywhere in the world. Nothing in these Terms of Use or the Services should be construed as granting, by implication, estoppel or otherwise, any license or right to use any of the Company’s logos, trademarks or information displayed on the Website, without the Company’s prior written permission in each instance. All goodwill generated from the use of the Company’s trademarks will inure to our exclusive benefit.</p>
                <ol>
                    <li>
                        <h6>License to Services.</h6>
                        <p>These Terms of Use permit you to use the Services, and anything available through the Website, for your personal (including on behalf of children under your care “Children”) and non-commercial use only. Unless you have entered a written agreement with the Company providing otherwise, you must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store or transmit any of the material on our Website, except as follows:</p>

                        <p>Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.</p>
                        <p>You may store files that are automatically cached by your Web browser for display enhancement purposes.</p>
                        <p>You may print one copy of a reasonable number of pages of the Website for your own personal, non-commercial use and not for further reproduction, publication or distribution.</p>
                        <p>If we provide desktop, mobile or other applications for download, you may download the mobile application(s) provided you agree to be bound by the applicable End User License Agreement for such applications.</p>
                        <p>If we provide social media features with certain content, you may take such actions as are enabled by such features.</p>

                        <p>Unless you have the Company’s permission, you must not:</p>

                        <p>Modify copies of any materials or information from this site.</p>
                        <p>Use any illustrations, photographs, video or audio sequences or any graphics separately from the accompanying text.</p>
                        <p>Delete or alter any copyright, trademark or other proprietary rights notices from copies of materials from this site.</p>

                        <p>If you print, copy, modify, download or otherwise use or provide any other person with access to any part of the Services in breach of the Terms of Use, your right to use the Services will cease immediately and you must, at our option, return or destroy any copies of the materials you have made. No right, title or interest in or to the Services or any content on the Website is transferred to you, and all rights not expressly granted are reserved by the Company. Any use of the Services not expressly permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark and other laws.</p>

                    </li>

                    <li>
                        <h6>Feedback.</h6>
                        <p>To the extent that you give us feedback, comments or suggestions concerning the Services (collectively, “Feedback”), you acknowledge and agree that all Feedback is the Company’s sole and exclusive property, and you hereby unconditionally and irrevocably assign and transfer to Simple Summers, your entire right, title and interest in and to any Intellectual Property Rights that you may now or hereafter have in or relating to the Services. To the extent that the foregoing assignment is ineffective for whatever reason, you agree to grant and hereby grant to the Company a non-exclusive, perpetual, irrevocable, royalty-free, worldwide license (with the right to grant and authorize sublicenses) to make, have made, use, import, offer for sale, sell, reproduce, distribute, modify, adapt, prepare derivative works of, display, perform, and otherwise exploit such Feedback without restriction. At our request and expense, you will execute documents and take such further acts as we may reasonably request to assist us in acquiring, perfecting and maintaining our Intellectual Property Rights in, and other legal protections for, the Feedback.</p>
                    </li>

                    <li>
                        <h6>Trademarks.</h6>
                        <p>The Company name, the Company logo and all related names, logos, product and service names, designs and slogans are trademarks and service marks of the Company or its affiliates or licensors. You must not use such marks without the prior written permission of the Company. All other names, logos, product and service names, designs and slogans used on this Website or in the Services are the trademarks and/or service marks of their respective owners.</p>
                    </li>

                    <li>
                        <h6>Prohibited Uses.</h6>
                        <p>You may use the Services only for lawful purposes and in accordance with these Terms of Use. You agree not to use the Services:</p>

                        <ul>
                            <li>In any way that is unlawful (including, without limitation, any laws regarding the export of data or software to and from the US or other countries), offensive, threatening, libelous, defamatory, pornographic, obscene, or otherwise objectionable, or violates any third party’s intellectual property rights or these Terms of Service.</li>
                            <li>To transmit, or procure the sending of, any advertising or promotional material, including any “junk mail”, “chain letter” or “spam” or any other similar solicitation.</li>
                            <li>To impersonate or attempt to impersonate the Company, a Company employee, another user or any other person or entity (including, without limitation, by using e-mail addresses or screen names associated with any of the foregoing).</li>
                            <li>To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the Services, or which, as determined by us, may harm the Company or users of the Services or expose them to liability.</li>
                        </ul>

                        <p>Additionally, you agree not to:</p>

                        <ul>
                            <li>Use the Services in any manner that could disable, overburden, damage, or impair the site or interfere with any other party’s use of the Services, including their ability to engage in real time activities through the Services.</li>
                            <li>Use any robot, spider or other automatic device, process or means to access the Services for any purpose, including monitoring or copying any of the material on the Services.</li>
                            <li>Use any manual process to monitor or copy any of the material on the Website or for any other unauthorized purpose without our prior written consent.</li>
                            <li>Use any device, software or routine that interferes with the proper working of the Services.</li>
                            <li>Introduce any viruses, trojan horses, worms, logic bombs or other material which is malicious or technologically harmful.</li>
                            <li>Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the Services, the server on which the Services are stored, or any server, computer or database connected to the Services.</li>
                            <li>Attack the Services via a denial-of-service attack or a distributed denial-of-service attack.</li>
                            <li>Otherwise attempt to interfere with the proper working of the Services</li>.
                        </ul>
                    </li>
                </ol>
            </li>

            <li>
                <h4>USER CONTRIBUTIONS.</h4>

                <p>The Website may contain message boards, Organization web pages or profiles, forums, bulletin boards, and other interactive features (collectively, “Interactive Services”) that allow Organizations’ authorized users to post, submit, publish, display or transmit to other users or other persons (hereinafter, “post”) content or materials (collectively, “User Contributions”) on or through the Services for display on the Website.</p>

                <p>All User Contributions must comply with the Content Standards set out in these Terms of Use. Any User Contribution you post to the site is considered non-confidential and non-proprietary. By providing any User Contribution, you grant us and our affiliates and service providers, and each of their and our respective licensees, successors and assigns the right to use, reproduce, modify, perform, display, distribute and otherwise disclose to third parties any such material for any purpose.</p>

                <p>You represent and warrant that:</p>

                <ul>
                    <li>You own or control all rights in and to the User Contributions and have the right to grant the license granted above to us and our affiliates and service providers, and each of their and our respective licensees, successors and assigns.</li>
                    <li>All of your User Contributions do and will comply with these Terms of Use.</li>

                    <p>You understand and acknowledge that you are responsible for any User Contributions you submit or contribute, and you, not the Company, have full responsibility for such content, including its legality, reliability, accuracy and appropriateness. You understand that the Company will attempt to review camp/event information but the Company is not responsible for the accuracy of such information submitted by any Organization.</p>
                </ul>

                <p>WE ARE NOT RESPONSIBLE, OR LIABLE TO ANY THIRD PARTY, FOR THE CONTENT OR ACCURACY OF ANY USER CONTRIBUTIONS POSTED BY YOU OR ANY OTHER USER OF THE WEBSITE.</p>

                <ol>
                    <li>
                        <h6>Monitoring and Enforcement; Termination.  </h6>
                        <p>We have the right to:</p>

                        <ul>
                            <li>Remove, edit, modify or refuse to post any User Contributions for any or no reason in our sole discretion.</li>
                            <li>Take any action with respect to any User Contribution that we deem necessary or appropriate in our sole discretion.</li>
                            <li>Disclose your identity or other information about you to any third party who claims that material posted by you violates their rights, including their intellectual property rights or their right to privacy.</li>
                            <li>Take appropriate legal action for any illegal or unauthorized use of the Website.</li>
                            <li>Terminate or suspend your access to all or part of the Website for any or no reason, including without limitation, any violation of these Terms of Use.</li>
                        </ul>

                        <p>Without limiting the foregoing, we have the right to fully cooperate, in accordance with applicable law, with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through the Website. YOU WAIVE AND HOLD HARMLESS THE COMPANY AND ITS AFFILIATES, LICENSEES AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY SIMPLE SUMMERS LLC DURING OR AS A RESULT OF ANY LAW ENFORCEMENT AUTHORITIES’ INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY SIMPLE SUMMERS LLC OR LAW ENFORCEMENT AUTHORITIES.</p>

                        <p>We do not screen material before it is posted on the Website and do not actively moderate User Contributions, so we cannot ensure prompt removal of objectionable material after it has been posted. Accordingly, we assume no liability for any action or inaction regarding transmissions, communications or content provided by any user or third party. We have no liability or responsibility to anyone for performance or nonperformance of the activities described in this section. If you have any complaints regarding any User Contributions, please contact us at hello@simplesummers.com.</p>
                    </li>

                    <li>
                        <h6>Content Standards.  </h6>
                        <p>These content standards apply to any and all User Contributions and use of Interactive Services. User Contributions must in their entirety comply with all applicable federal, state, local and international laws and regulations. Without limiting the foregoing, User Contributions must be accurate and must not:</p>

                        <ul>
                            <li>Contain any material which is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory or otherwise objectionable.</li>
                            <li>Promote any illegal activity, or advocate, promote or assist any unlawful act, including the promotion of sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation or age.</li>
                            <li>Infringe any patent, trademark, trade secret, copyright or other intellectual property or other rights of any other person.</li>
                            <li>Violate the legal rights (including the rights of publicity and privacy) of others or contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with these Terms of Use and our Privacy Policy.</li>
                            <li>Be likely to deceive any person or cause annoyance, inconvenience or needless anxiety or be likely to upset, embarrass, alarm or annoy any other person.</li>
                            <li>Impersonate any person, or misrepresent your identity or affiliation with any person or organization.</li>
                            <li>Involve commercial activities or sales, such as contests, sweepstakes and other sales promotions, barter or advertising.</li>
                            <li>Give the impression that they emanate from or are endorsed by us or any other person or entity.</li>
                        </ul>
                    </li>
                </ol>
            </li>

            <li>
                <h4>COPYRIGHT INFRINGEMENT.</h4>
                <p>We take claims of copyright infringement seriously. We will respond to notices of alleged copyright infringement that comply with applicable law. If you believe any materials accessible on or from the Services (including the Website) infringe your copyright, you may request removal of those materials (or access to them) from the Services by submitting written notification to us at hello@simplesummers.com. It is the Company’s policy to terminate the user accounts of repeat infringers.</p>
            </li>

            <li>
                <h4>RELIANCE ON INFORMATION POSTED.</h4>
                <p>The information presented on or through the Website is made available solely for general information purposes. We do not warrant the accuracy, completeness or usefulness of this information. Any reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other visitor to the Website, or by anyone who may be informed of any of its contents.</p>

                <p>This Website includes content provided by third parties, including materials provided by other users, bloggers and third-party licensors, syndicators, aggregators and/or reporting services. All information, statements and/or opinions expressed in these materials, and all articles and responses to questions and other content, other than the content provided by the Company, are solely the opinions and the responsibility of the person or entity providing those materials. These materials do not necessarily reflect the opinion of the Company. We are not responsible, or liable to you or any third party, for the content or accuracy of any materials provided by any third parties.</p>

                <p>The Website is provided and intended for informational purposes only. As set forth in the Website Terms of Use and other applicable license, Simple Summers reserves the right to alter or change the Services and the Website as it deems fit.</p>
            </li>

            <li>
                <h4>CHANGES TO THE WEBSITE.</h4>
                <p>We may update the content available through the Services from time to time, but its content is not necessarily complete or up-to-date. Any of the material available through the Services may be out of date at any given time, and we are under no obligation to update such material. From time to time, the Company may issue an update to the Services, which may add, modify, and/or remove features; these updates may be pushed out automatically with little or no notice.</p>
            </li>

            <li>
                <h4>INFORMATION ABOUT YOU AND YOUR VISITS TO THE WEBSITE.</h4>
                <p>All information we collect through the Services is subject to our Privacy Policy. By visiting the Website and using the Services, you consent to all actions taken by us with respect to your information in compliance with the Privacy Policy.</p>
            </li>

            <li>
                <h4>ONLINE PURCHASES AND OTHER TERMS AND CONDITIONS.</h4>
                <p>All purchases through our Services or other transactions for the sale of services or information formed through the Services or as a result of visits made by you are governed by the applicable terms of purchase. Additional terms and conditions may also apply to specific portions, services or features of the Services, including without limitation other subscription or license agreement(s) entered into by the parties. All such additional terms and conditions are hereby incorporated by this reference into these Terms of Use. To the extent any conflict exists between these Terms of Use or any other agreements with the Company, the following order of precedence shall apply: (1) the other agreement, and (2) these Terms of Use. The Privacy Policy shall control with respect to all matters of privacy and data processing.</p>
            </li>

            <li>
                <h4>LINKING TO THE WEBSITE AND SOCIAL MEDIA FEATURES.</h4>
                <p>You may link to our homepage, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part without our express written consent.</p>

                <p>This Website may provide certain social media features that enable you to:</p>

                <ul>
                    <li>Link from your own or certain third-party websites to certain content on this Website.</li>
                    <li>Send e-mails or other communications with certain content, or links to certain content, on this Website.</li>
                    <li>Cause limited portions of content on this Website to be displayed or appear to be displayed on your own or certain third-party websites.</li>
                </ul>

                <p>You may use these features solely as they are provided by us with respect to the content they are displayed with and otherwise in accordance with any additional terms and conditions we provide with respect to such features. Subject to the foregoing, you must not:</p>

                <ul>
                    <li>Establish a link from any website that is not owned by you.</li>
                    <li>Cause the Website or portions of it to be displayed, or appear to be displayed by, for example, framing, deep linking or in-line linking, on any other site.</li>
                    <li>Link to any part of the Website other than the homepage.</li>
                    <li>Otherwise take any action with respect to the materials available through the Services that is inconsistent with any other provision of these Terms of Use.</li>
                </ul>

                <p>You agree to cooperate with us in causing any unauthorized framing or linking immediately to cease. We reserve the right to withdraw linking permission without notice. We may disable all or any social media features and any links at any time without notice in our discretion.</p>
            </li>

            <li>
                <h4>LINKS FROM OUR WEBSITE</h4>
                <p>If the Services contain links to other sites and resources provided by third parties, these links are provided for your convenience only. This includes links contained in advertisements, including banner advertisements and sponsored links. We have no control over the contents of those sites or resources, and accept no responsibility for them or for any loss or damage that may arise from your use of them. If you decide to access any of the third party websites linked to the Services, you do so entirely at your own risk and subject to the terms and conditions of use for such websites.</p>
            </li>

            <li>
                <h4>GEOGRAPHIC RESTRICTIONS</h4>
                <p>The owner of the Website is based in the state of California in the United States. We make no claims that the Services or any of its content is accessible or appropriate outside of the United States. Access to the Website may not be legal by certain persons or in certain countries. If you access the Website from outside the United States, you do so on your own initiative and are responsible for compliance with local laws.</p>
            </li>

            <li>
                <h4>DISCLAIMER OF WARRANTIES</h4>
                <p>You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Services will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.</p>

                <p>YOUR USE OF THE SERVICES, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES IS AT YOUR OWN RISK. THE SERVICES, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE WEBSITE OR THE CONTENT. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.</p>

                <p>THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
            </li>

            <li>
                <h4>LIMITATION ON LIABILITY</h4>
                <p>IN NO EVENT WILL THE MAXIMUM AGGREGATE LIABILITY OF THE COMPANY AND AFFILIATES, AND THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS AND DIRECTORS, TO ANY PARTY (REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT OR OTHERWISE) EXCEED THE AMOUNT YOU HAVE PAID TO THE COMPANY FOR ACCESS TO THE APPLICABLE SERVICES IN THE SIX MONTHS PRECEDING THE CLAIM GIVING RISE TO SUCH LIABILITY. THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
            </li>

            <li>
                <h4>INDEMNIFICATION</h4>
                <p>You agree to defend, indemnify and hold harmless the Company, its affiliates, licensors and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys’ fees) arising out of or relating to your violation of these Terms of Use or your use of the Services, including, but not limited to, your User Contributions, any use of the Website’s content, services and products other than as expressly authorized in these Terms of Use or your use of any information obtained from the Services.</p>
            </li>

            <li>
                <h4>GOVERNING LAW AND JURISDICTION</h4>
                <p>All matters relating to the Services and the Terms of Use and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the laws of the State of California without giving effect to any choice or conflict of law provision or rule. Any legal suit, action or proceeding arising out of, or related to, the Terms of Use or the Services shall be instituted exclusively in the federal courts of the United States or the courts of the State of California. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.</p>
            </li>

            <li>
                <h4>LIMITATION ON TIME TO FILE CLAIMS</h4>
                <p>ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THE TERMS OF USE OR THE SERVICES MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.</p>
            </li>

            <li>
                <h4>WAIVER AND SEVERABILITY</h4>
                <p>No waiver by the Company of any term or condition set forth in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of the Company to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision. If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Use will continue in full force and effect.</p>
            </li>

            <li>
                <h4>ENTIRE AGREEMENT</h4>
                <p>The Terms of Use and our Privacy Policy constitute the sole and entire agreement between you and Simple Summers LLC with respect to the Website and Services, and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to the Website and Services.</p>
            </li>

        </ol>

        <h6>Your Comments and Concerns</h6>
        <p>This Website and the Services are operated by Simple Summers LLC.</p>

        <p>All other feedback, comments, requests for technical support and other communications relating to the Website should be directed to: hello@simplesummers.com.</p>

        </div>
      </div>
    </div>

  </Layout>
)

export default StandardPage
